.effort-tile {
    position: absolute;
    border: 1px solid white;
    border-radius: 5px;
    transition: all 0.3s;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    display: block;
    text-decoration: none;
}

.effort-tile-inner {
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    padding: 0.5em;
    overflow: hidden;
}

.effort-tile-inner > div {
    width: 100%;
}

.effort-tile p {
    margin: 0;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    font-weight: 400;
    z-index: 2;
    text-align: center;
    font-size: 0.8em;
    transition: all 0.3s;
}

.effort-tile-inner p {
    display: inline-block;
    margin: 0;
}

.effort-tile-inner p:first-child {
    width: 0%;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 0.25em;
}

.effort-tile-inner p:last-child {
    text-align: left;
    overflow: hidden;
    margin-left: 0.25em;
    width: calc(100% - 0.5em);
}

.effort-tile:hover {
    width: 100% !important;
    left: 0px !important;
    z-index: 100;
}

.effort-tile:hover p {
    font-size: 1em;
}

.effort-tile:hover .effort-tile-inner p:last-child {
    width: calc(50% - 0.5em);
}

.effort-tile:hover .effort-tile-inner p:first-child {
    width: calc(50% - 0.5em);
}

.effort-tile-entries {
    position: relative;
}

.effort-tile-entries > div {
    position: absolute;
    width: 100%;
    height: 10px;
    z-index: 1;
    overflow-x: hidden;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.effort-tile-entries > div > div {
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,0.3);
    border-radius: 50%;
}