

.search-form {
    display: inline-block;
    margin-right: 1em;
    position: relative;
}

.search-input {
    padding: 0.25em 0.5em;
    font-size: 1.1em;
    width: 10em;
    background-color: #444;
    border: 0;
    border-radius: 5px;
    color: #fff;
}

@media screen and (max-width: 640px) {
    .search-input {
        min-width: 6em;
        font-size: 1em;
        padding: 0.5em;
    }
}

@media screen and (max-width: 320px) {
    .search-input {
        min-width: 3em;
        max-width: 6em;
        font-size: 1em;
    }
}

.search-results {
    position: absolute;
    left: 0;
    top: 32px;
    width: 500px;
    background-color: #222;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.4);
}

@media screen and (max-width: 640px) {
    .search-results {
        left: 0;
        top: 48px;
        width: 100%;
        z-index: 500;
    }
}

.search-result {
    border-bottom: 1px solid #444 !important;
    display: block !important;
    color: #fff !important;
    padding: 0.5em 1em !important;
}

.search-result:last-child {
    border-bottom: 0 !important;
}

.search-result h2 {
    width: 100%;
    font-size: 1.2em;
    margin: 0;
    font-weight: 500;
}

.search-result p {
    width: 100%;
    margin: 0;
}