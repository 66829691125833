.entry-list-item {
    margin-bottom: 2em;
}

.entry-list-item-page-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1em 0;
    font-size: 1.2em;
}

.entry-list-item .image-gallery-container {
    margin-top: 2em;
}