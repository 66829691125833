@import url(https://fonts.googleapis.com/css2?family=Patua+One&display=swap);


.search-form {
    display: inline-block;
    margin-right: 1em;
    position: relative;
}

.search-input {
    padding: 0.25em 0.5em;
    font-size: 1.1em;
    width: 10em;
    background-color: #444;
    border: 0;
    border-radius: 5px;
    color: #fff;
}

@media screen and (max-width: 640px) {
    .search-input {
        min-width: 6em;
        font-size: 1em;
        padding: 0.5em;
    }
}

@media screen and (max-width: 320px) {
    .search-input {
        min-width: 3em;
        max-width: 6em;
        font-size: 1em;
    }
}

.search-results {
    position: absolute;
    left: 0;
    top: 32px;
    width: 500px;
    background-color: #222;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.4);
}

@media screen and (max-width: 640px) {
    .search-results {
        left: 0;
        top: 48px;
        width: 100%;
        z-index: 500;
    }
}

.search-result {
    border-bottom: 1px solid #444 !important;
    display: block !important;
    color: #fff !important;
    padding: 0.5em 1em !important;
}

.search-result:last-child {
    border-bottom: 0 !important;
}

.search-result h2 {
    width: 100%;
    font-size: 1.2em;
    margin: 0;
    font-weight: 500;
}

.search-result p {
    width: 100%;
    margin: 0;
}
.main-nav {
    min-height: 64px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    margin-bottom: 2em;
    z-index: 100;
    position: relative;
}

.main-nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    position: relative;
    z-index: 100;
}

@media screen and (max-width: 640px) {
    .main-nav .container {
        box-shadow: 5px 5px 5px rgba(0,0,0,0.2);     
    }
}

.main-nav .logo {
    height: 64px;

    font-size: 1.4em;
    font-weight: bold;
    color: #B294E6;
    
    text-decoration: none;
}

.main-nav .logo div {
    display: inline-block;
    height: 100%;
}

.main-nav .logo a {
    padding: 0 1em;
    margin-left: -1em;
}

@media screen and (max-width: 640px) {
    .main-nav .logo a {
        padding-right: 0;
    }
}

.main-nav ul {
    padding: 0;
    margin: 0 2em 0 0;
    height: 64px;
    display: inline-block;
    
    list-style: none;
}

.main-nav ul:last-child {
    margin: 0;
}

.main-nav li {
    display: inline-block;
    height: 100%;
}

.main-nav a {
    display: flex;
    align-items: center;
    padding: 0 0.5em;
    height: 100%;

    text-decoration: none;
    font-size: 1.1em;

    color: #B294E6;
    border-bottom: 3px solid rgba(207, 236, 231, 0);
    background-color: rgba(207, 236, 231, 0);
}

@media screen and (max-width: 640px) {
    .main-nav a {
        padding: 0 0.5em;
    }
}

@media screen and (min-width: 641px) {
    .main-nav a {
        transition: all 0.3s;
    }
    .main-nav a:hover {
        border-bottom: 3px solid rgba(207, 236, 231, 1);
        background-color: rgba(207, 236, 231, 0.04);
    }
}

.hamburger-button {
    border: 0;
    color: white;
    font-size: 1.4em;
    width: 45px;
    height: 45px;
    display: grid;
    place-items: center;
    display: inline-block;
}

.hamburger-interior .search-form {
    padding: 0.5em;
    width: 100%;
}

.hamburger-interior .search-input {
    width: 100%;
}
.loading-container {
    width: 100%;
    display: grid;
    place-items: center;
}

.loading-inner {
  display: grid;
  place-items: center;
}

.loading-spinner {
    border: 16px solid #B294E6; /* Light grey */
    border-top: 16px solid #fff; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
  }

  .loading-container div p {
    text-align: center;
  }
  
  @-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
@media screen and (min-width: 641px) {
    .landing {
        /* Dunno why I need to do this but I uh...I do */
        margin-bottom: 100px !important;
    }
}



/* --------LANDING TOP-------- */

.landing-top {
    height: calc(100vh - 64px);
    overflow-y: hidden;
    margin-bottom: 4em;
}

.landing-top h1 {
    font-size: 5.5em;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
    font-family: 'Patua One', cursive;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
}

.landing h1 span:last-child {
    margin: -0.4em 0 0 0.5em;
}

.landing-intro {
    width: 40%;
    font-size: 1.5em;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    position: relative;
    margin-top: -450px;
}

.landing-sketch {
    position: relative;
    width: 800px;
    height: 800px;
}

@media screen and (max-width: 640px) {
    .landing h1 {
        position: relative;
        z-index: 5;
        margin-top: 70vw;
        margin-bottom: 0;
        font-size: 3em;
        flex-direction: row;
    }

    .landing h1 span:last-child {
        margin: 0 0 0 0.25em;
    }

    .landing-intro {
        font-size: 1.25em;
        width: 100%;
        z-index: 5;
        position: relative;
        margin-top: 0;
    }

    .landing-intro p {
        margin: 0.5em 0;
    }

    .landing-sketch {
        position: relative;
        width: 100%;
        height: auto;
        z-index: 4;
        margin-top: calc(-70vw - 32px);
    }
}

#tsparticles {
    position: absolute;
    top: 64px;
    right: 0;
    width: 100vw;
    height: calc(300vh - 64px);
    z-index: -10;
}

@media screen and (min-width: 641px) {
    .landing-sketch {
        margin-top: -375px;
        margin-left: 200px;
    }
}
@media screen and (max-width: 640px) {
    /* move everything up */
    .landing-selected {
        margin-top: -300px !important;
    }
    
    .landing-current {
        margin-top: -200px !important;
    }

    .landing-about {
        margin-top: -200px !important;    
    }
}




/* --------LANDING SELECTED-------- */
.landing-selected {
    margin-bottom: 4em;
}
.selected-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
    margin-bottom: 2em;
}
@media screen and (max-width: 640px) {
    .selected-grid {
        grid-template-columns: 1fr;
    }
}
.selected-project {
    display: block;
    text-decoration: none;
    height: 18em;
    background-size: cover;
    background-position: center;
}
.selected-project-content {
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
    background-position: 0% 8em;
    background-size: 200% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    padding: 1em;
    cursor: pointer;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
}
@media screen and (min-width: 641px) {
    .selected-project-content {
        transition: all 0.3s;
    }
    .selected-project-content:hover {
        background-position: 0% 18em;
        text-shadow: 5px 5px 5px rgba(0,0,0,0.75);
    }
}
.selected-project-content h2 {
    margin: 0;
}
.selected-project-content h4 {
    margin: 0;
    width: 80%;
}
.landing-selected .see-all {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
}





/* --------LANDING CURRENT-------- */
.landing-current {
    margin-top: 300px;
}
.landing-current-sidebyside {
    display: flex;
}
.landing-current-sidebyside > div {
    width: 50%;
}
.landing-current-sidebyside > div:first-child {
    margin-right: 1em;
}
.landing-current h1 {
    margin-bottom: 0;
}
@media screen and (max-width: 640px) {
    .landing-current-sidebyside {
        flex-direction: column;
    }
    .landing-current-sidebyside > div {
        width: 100%;
        margin: 0 0 2em 0;
    }
}
.current-effort {
    display: grid;
    text-decoration: none;
    place-items: center left;
    height: 10em;
    background-color: rgba(0,0,0,0.5);
    padding: 0.5em 1em;
    margin-bottom: 1em;
}
.current-effort h3 {
    margin: 0;
}
.current-effort p {
    margin: 0;
}
.current-effort .effort-type {
    margin-top: 0.5em;
}
.recent-entry {
    height: 10em;
    background-size: cover;
    background-position: center;
    margin-bottom: 1em;
}
.recent-entry-inner {
    text-decoration: none;
    display: grid;
    place-items: center left;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    padding: 0.5em 1em;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
}
.recent-entry h3, .recent-entry p {
    margin: 0;
}
.recent-entry-preview {
    margin-top: 0.5em;
    width: 100%;
    overflow-y: hidden;
    height: 2.6em;
}
.recent-entry-preview p {
    width: 100%;
    word-wrap: break-word;
    position: relative;
}

@media screen and (min-width: 641px) {
    .current-effort, .recent-entry-inner {
        cursor: pointer;
        transition: all 0.3s;
    }
    .current-effort:hover, .recent-entry-inner:hover {
        background-color: rgba(0,0,0,0.1);
    }
}





/* --------LANDING ABOUT-------- */
.landing-about {
    margin-top: 300px;
}
.landing-about h1 {
    margin-bottom: 0;
}
.landing-about-sidebyside {
    display: flex;
}
.landing-about-sidebyside > div {
    width: 50%;
}
.landing-about-sidebyside > div:last-child {
    display: grid;
    place-items: center;
}
.landing-about img {
    width: 80%;
    height: auto;
}
@media screen and (max-width: 640px) {
    .landing-about-sidebyside {
        flex-direction: column;
    }
    .landing-about-sidebyside > div {
        width: 100%;
        margin-bottom: 2em;
    }
}
.timeline-container {
    height: 5000px;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.timeline-project {
    display: inline-block;
    height: 5000px;
}

.timeline-line {
    position: absolute;
    width: 1px;
    background-color: #fff;
    transform: translateX(10px);
    transition: all 0.3s;
}

.timeline-entry {
    display: block;
    position: absolute;
    height: 10px;
    width: 20px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 30%;
    z-index: auto;
    transition: all 0.3s;
}

.timeline-entry:hover {
    background-color: #fff !important;
    z-index: 2;
    width: 30px;
    transform: translateX(-5px);
}

.timeline-hover-panel {
    position: absolute;
    z-index: 5;
    background-color: #333333;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.5);
    padding: 0.5em 1em;
    width: 300px;

    transition: all 0.3s;
}

.timeline-hover-panel h3 {
    margin: 0;
    border-bottom: 1px solid white;
}

.timeline-hover-panel h4 {
    margin: 0;
    margin-bottom: 0.2em;
}

.timeline-hover-panel p {
    margin: 0;
    font-style: italic;
}

.timeline-project-title-top, .timeline-project-title-bottom {
    position: absolute;
    text-align: left;
    margin: 0;
    transition: all 0.3s;
}

@media screen and (max-width: 640px) {
    .timeline-project-title-top, .timeline-project-title-bottom {
        font-size: 0.8em;
    }
}

.timeline-project-title-top {
    transform-origin: center right;
    transform: translateX(20px) translateY(-20px) rotate(40deg);
}

.timeline-project-title-bottom {
    transform-origin: center left;
    transform: translateX(5px) translateY(10px) rotate(40deg);
}

.timeline-month-marker {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    height: 1px;
    z-index: -1;
}

.timeline-month-marker p {
    margin: 0;
    transform: translateY(-1.25em);
    font-weight: normal;
    font-size: 1.5em;
}
.project-tile {
    background-size: cover;
    background-position: center center;
    height: 200px;
}

.project-tile a {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    height: 200px;
    width: 100%;
    padding: 0 40px;

    text-decoration: none;
}

.project-tile h2 {
    margin: 0;
    margin-bottom: 0 !important;
    text-shadow: 2px 2px rgba(0,0,0,0.8);
}

.project-tile p, .project-tile strong {
    margin: 0;
    text-shadow: 2px 2px rgba(0,0,0,0.8);
}

@media screen and (max-width: 640px) {
    .project-tile .project-description {
        display: none;
    }
}
.project-tile-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    grid-gap: 1.5em;
    gap: 1.5em;
    padding-top: 2em;
}

@media screen and (max-width: 640px) {
    .project-tile-container {
        grid-template-columns: 1fr;
    }
}

.project-group {
    padding-bottom: 2rem;
}

.project-group h2 {
margin-bottom: -1rem;
}

.projects-intro p {
    margin: 0;
    font-style: italic;
    font-size: 1.2em;
}

.filters {
    margin-bottom: 4em;
}

.filters > div > div {
    display: grid;
    grid-gap: 2em;
}

@media screen and (max-width: 640px) {
    .filters > div > div {
        display: flex;
        flex-direction: column;
    }
}

.filters > div > div > div > ul {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    padding-top: 1em;
}

.filters .one {
    grid-column: 1/1;
}

.filters .three {
    grid-column: 2/3;
}

.filters .one:last-child {
    grid-column: 3/3;
}

.filters .three > ul {
    grid-template-columns: 1fr 1fr 1fr;
}

.filters ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.filters li {
    text-align: center;
    font-size: 1.2em;
    background-color: rgba(0,0,0,0.5);
    height: 2em;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.filters h2, .filters h3 {
    text-align: center;
    margin-bottom: 0;
}

.filters h2 {
    padding: 0.25em 0;
    cursor: pointer;
}

.filters h3 {
    border-bottom: 1px solid white;
    padding-bottom: 0.25em;
    width: 60%;
    margin: auto;
    margin-top: 0.5em;
}

.filters p {
    text-align: center;
    font-size: 1.2em;
}

.filters p > span {
    margin-left: 0.25em;
    cursor: pointer;
    padding: 0.5em 1em;
}

.active-filter {
    border: 2px solid white;
    background-color: rgba(0,0,0,0.8) !important;
}

.match-button-container {
    width: 100%;
    text-align: center;
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.match-button {
    border: 0;
    padding: 0.5em 1em;
    font-size: 1.2em;
    margin: 0 auto;
    cursor: pointer;
}

:root {
    --label-width: 15%;
    --input-width: 85%;
}

@media screen and (max-width: 640px) {
    :root {
        --label-width: 100%;
        --input-width: 100%;
    }
}

.form-element {
    padding: 0.5em 0;
}

.form-element div span {
    display: inline-block;
    width: 15%;
    width: var(--label-width);
    margin: 0;
    font-size: 1.2em;
    text-align: right;
    padding: 0.5em 1em 0 0;
}

.form-element div input, 
.form-element div select, 
.form-element .checkbox-input-wrapper {
    display: inline-block;
    width: 85%;
    width: var(--input-width);
    font-size: 1.2em;
    padding: 0.5em;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0.5em !important;
}

.checkbox-wrapper span {
    padding: 0 1em 0.2em 0 !important;
}

.form-element .checkbox-input-wrapper {
    padding: 0 !important;
}

.form-element .checkbox-input-wrapper input {
    width: 1.4em;
    height: 1.4em;
    margin: 0;
}

@media screen and (max-width: 640px) {
    .form-element div span {
        display: block;
        text-align: left;
    }
}

.form-element ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 85%;
    width: var(--input-width);
    margin-left: 15%;
    margin-left: var(--label-width);
}

.form-element ul li {
    font-size: 1.2em;
    padding: 0.25em 0.5em;
    background-color: #111;
}

.form-element ul li.suggestion-active {
    background-color: #333 !important;
}

.form-element textarea {
    width: 85%;
    width: var(--input-width);
    font-size: 1.2em;
    padding: 0.5em;
    height: 33em;
}

.form-element .textarea-wrapper, .form-element .images-wrapper {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 640px) {
    .form-element .textarea-wrapper, .form-element .images-wrapper {
        display: block;
    }
}

.react-datepicker-wrapper {
    width: 85% !important;
    width: var(--input-width) !important;
}
.react-datepicker-wrapper div {
    width: 100% !important;
}
.react-datepicker-wrapper input {
    width: 100% !important;
}

.images-container {
    width: 85%;
    width: var(--input-width);
}


.images-item, .image-item-single {
    width: 100%;
    display: grid;
    grid-gap: 0.5em;
    margin-bottom: 1em;
}
.images-item {
    grid-template-columns: 14fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 0.5fr;
}

.image-item-single {
    grid-template-columns: 15fr 1fr;
    grid-template-rows: 1fr 0.5fr;
}

.images-item div:first-child, .image-item-single div:first-child {
    grid-row: 1 !important;
}
.images-item div.nocaption:first-child {
    grid-row: 1/3 !important;
    display: grid;
    place-items: center;
}
.images-item div {
    grid-column: 1;
    grid-row: 2;
}
.images-item button {
    margin-top: 0 !important;
    padding: 0.5em !important;
}
.images-item button.cancel {
    grid-column: 2;
    grid-row: 1 / span 2;
}
.image-item-single button.cancel {
    grid-column: 2;
    grid-row: 1;
    margin: 0;
}
.images-item button.up {
    grid-column: 3;
    grid-row: 1;
}
.images-item button.down {
    grid-column: 3;
    grid-row: 2;
}
.images-item input, .image-item-single input {
    width: 100% !important;
}

.images-item .form-element, .image-item-single .form-element {
    padding: 0;
    display: grid;
    place-items: center;
    width: 100%;
}

.images-item .form-element .input-wrapper, .image-item-single .form-element .input-wrapper {
    width: 100%;
}

.images-item .error, .image-item-single .error {
    padding: 0 !important;
    width: 100%;
    text-align: left;
}

.images-container button {
    margin: 0.5em 0 0 0;
    padding: 0.5em 2em;
    font-size: 1.2em;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    border: none;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.3);
    cursor: pointer;

    transition: 0.3s all;
}

.images-container button:hover {
    box-shadow: 2px 8px 8px rgba(0,0,0,0.4);
    background-color: rgba(0,0,0,0.6);
}

.images-item .upload-progress-container, .image-item-single .upload-progress-container {
    width: 100%;
    height: 1.5em;
    position: relative;
    border: 1px solid white;
    border-radius: 0.25em;
}

.images-item .upload-progress-container {
    grid-column: 1 / span 3;
    grid-row: 3 / 3;
}

.image-item-single .upload-progress-container {
    grid-column: 1 / span 2;
    grid-row: 2 / 2;
}

.upload-progress-container .upload-progress-bar, .image-item-single .upload-progress-bar {
    position: absolute;
    height: 100%;
    width: 0%;
    background-color: #B294E6;
    transition: width 0.3s;
}

.upload-progress-container p {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 200;
    margin: 0em 0 0 0;
}

.images-item .dropzone, .image-item-single .dropzone {
    border: 2px dashed #666;
    text-align: center;
}

.form-element .buttons-wrapper {
    padding-left: 15%;
    padding-left: var(--label-width);
    margin-top: 2em;
}

.form-element .buttons-wrapper button {
    padding: 0.5em 2em;
    font-size: 1.4em;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    border: none;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.3);
    cursor: pointer;
    margin-right: 2em;
}

@media screen and (max-width: 640px) {
    .form-element .buttons-wrapper {
        padding: 0;
    }

    .form-element .buttons-wrapper button {
        margin-bottom: 1em;
    }    
}

.button-danger {
    background-color: rgba(100,30,30,0.4) !important;
}

@media screen and (min-width: 641px) {
    .form-element .buttons-wrapper button:hover {
        box-shadow: 2px 8px 8px rgba(0,0,0,0.4);
        background-color: rgba(0,0,0,0.6);
        transition: 0.3s all;
    }

    .button-danger:hover {
        background-color: rgba(150,30,30,0.6) !important;
    }
}

.form-element .error {
    padding-left: 15%;
    padding-left: var(--label-width);
    color: rgb(240, 117, 117);
}

@media screen and (max-width: 640px) {
    .form-element .error {
        padding: 0;
    }

    
}

.expand-wrapper span:last-child {
    padding: 0.5em !important;
    width: 4em;
    text-align: center;
    cursor: pointer;
}
.content-previewable-wrapper {
    display: flex;
}

.content-previewable-wrapper .form-element {
    width: 85% !important;
    width: var(--input-width) !important;
}

.content-previewable-wrapper .form-element textarea {
    width: 100% !important;
    font-size: 1.2em;
    padding: 0.5em;
    height: 33em;
}

.content-previewable-label {
    width: 15%;
    width: var(--label-width);
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    text-align: right;
    padding: 0.5em 1em 0 1em;
}

.content-previewable-label span {
    margin-bottom: 1em;
}

.content-previewable-label button {
    padding: 0.5em;
    font-size: 1em;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    border: none;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.3);
    cursor: pointer;
}

.content-image-previews {
    display: flex;
    justify-content: center;
    height: 8em;
}

.content-image-previews img {
    height: 100%;
    width: auto;
    margin: 0 0.5em;
}

@media screen and (max-width: 640px) {
    .content-previewable-wrapper {
        flex-direction: column;
    }

    .content-previewable-label {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;
    }

    .content-previewable-label span {
        margin-bottom: 0;
    }
}

.content-preview {
    padding: 0.5em;
    background-color: #333;
    width: 85%;
    width: var(--input-width);
}

.content-preview *:first-child {
    margin-top: 0.25em;
}

.tags-wrapper {
    margin-bottom: 1em;
}

.tags-wrapper button {
    margin-left: 15%;
    margin-left: var(--label-width);
    border: none;
    padding: 0.5em 1em;
    font-size: 1.2em;
    cursor: pointer;
}

.tags-wrapper .tags-display {
    margin-left: 15%;
    margin-left: var(--label-width);
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.5em;
    height: 4em;
}

.tags-display > .tag-item {
    margin-right: 1em;
    padding: 0.25em 0.75em 0.4em 0.75em;
    background-color: #111;
    border-radius: 1em;
}

.tag-item > span:last-child {
    margin-left: 0.5em;
    cursor: pointer;
}

.radio-wrapper .label {
    display: inline-block !important;

}

.radio-wrapper .radio-options {
    display: inline-flex !important;
}

.radio-options .radio-option input {
    display: none !important;
}

.radio-options .radio-option label {
    width: 5rem;
    height: 5rem;
    display: grid;
    place-items: center;
    font-size: 2rem;
    transition: all 0.3s;
    cursor: pointer;
}

.radio-options .radio-option label:hover {
    background-color: rgba(0,0,0,0.2);
}

.option-note .radio-options .radio-option:nth-child(1),
.option-elaboration .radio-options .radio-option:nth-child(2),
.option-experimentation .radio-options .radio-option:nth-child(3) {
    background-color: rgba(0,0,0,0.5);
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
}
.image-gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gallery-image-active {
    display: grid;
    place-items: center;
    margin: 1em 0;
}

.gallery-image-active div {
    display: grid;
    place-items: center;
    width: 100%;
    /* height: 500px; */
}

/*
.gallery-image-active img {
    max-height: 500px;
    max-width: 650px;
}
*/

.image-gallery-container figcaption {
    /* max-width: 650px; */
    text-align: center;
    font-style: italic;
    min-height: 2em;
    margin: 0 auto;
    margin-bottom: 1em;
}

.gallery-image-inactive {
    display: grid;
    place-items: center;
    margin: 0.3em;
    /* height: 150px;
    width: 150px; */

    cursor: pointer;

    transition: all 0.3s;
    border: 1px solid rgba(255,255,255,0);
}

.gallery-image-selected {
    border: 2px solid white !important;
}

/*
.gallery-image-inactive img {
    max-height: 145px;
    max-width: 145px;
}
*/

.gallery-image-inactive:hover {
    border: 1px solid rgba(255,255,255,1);
}
.project-detail-nav {
    height: 64px;
    margin-left: -9999em;
    padding-left: 9999em;
    margin-right: -9999em;
    padding-right: 9999em;
    background-color: #333;
    position: relative;
    z-index: 5;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
    margin-bottom: 2em;
}

.project-detail-nav .container {
    height: 100%;
    padding: 0;
}

.project-detail-nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    margin: 0;
}

.project-detail-nav ul li {
    display: block;
    text-align: center;
    height: 100%;
}

.project-detail-nav ul li a {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding: 0 1em;
    font-size: 1.2em;
}

@media screen and (max-width: 640px) {
    .project-detail-nav ul {
        justify-content: space-around;
    }

    .project-detail-nav ul li a {
        font-size: 0.8em;
        padding: 0 0.5em;
    }
}

.project-detail-nav ul li a:hover {
    background-color: rgba(0,0,0,0.3);
}

.project-detail-nav li.nav-selected {
    background-color: #111;
}

.project-detail-header-wrapper {
    width: 100%;
    margin-top: -2em;
    height: 200px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
    position: relative;
    z-index: 50;
    background-size: cover;
    background-position: center;
}

.project-detail-header {
    width: 100%;
    height: 100%;
    padding: 2em calc((100% - 1000px) / 2);
    display: flex;
    align-items: center;
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    background-color: rgba(0,0,0,0.4);
}

.project-detail-header h1 {
    font-size: 3em;
    margin-bottom: 0;
}

.project-detail-header p {
    margin-top: 0;
    font-size: 1.5em;
}

@media screen and (max-width: 640px) {
    .project-detail-header {
        padding-left: 1em;
    }

    .project-detail-header h1 {
        font-size: 2em;
    }

    .project-detail-header p {
        font-size: 1.2em;
    }
}

.project-detail-body .image-gallery-container {
    margin-top: 4em;
}

.entry-tile-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    grid-gap: 1.5em;
    gap: 1.5em;
    padding-top: 2em;
}

@media screen and (max-width: 640px) {
    .entry-tile-container {
        grid-template-columns: 1fr;
    }
}

.project-detail-gallery {
    display: flex;
    flex-wrap: wrap;
}

.project-gallery-content-wrapper {
    background-size: cover;
    background-position: center;

    position: relative;
    flex-basis: calc(33% - 2em);
    margin: 1em;
}

@media screen and (max-width: 640px) {
    .project-gallery-content-wrapper {
        background-size: cover;
        background-position: center;
    
        position: relative;
        flex-basis: calc(100% - 2em);
        margin: 1em;
    }
}

.project-gallery-content-wrapper::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.project-gallery-content {
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;

    display: flex;               /* added for centered text */
    justify-content: center;     /* added for centered text */
    align-items: center;         /* added for centered text */
}

@media screen and (min-width: 641px) {
    .project-gallery-content {
        transform: scale(1,1);
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        transition: all 0.5s;
    }

    .project-gallery-content:hover {
        transform: scale(1.025, 1.025);
        box-shadow: 0 10px 10px 0 rgba(0,0,0,0.25);
    }
}

.project-gallery-content img, .project-gallery-content video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.entry-container {
    font-size: 1.2em;
}

.entry-item {
    display: flex;
    justify-content: space-between;
    height: 2.4em;
    padding: 0.5em 0;
    text-decoration: none;
    position: relative;
    font-size: 1.2em;
    padding-right: 1em;
}

.entry-item p {
    margin: 0;
}

.entry-item > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.entry-item .circle {
    display: inline-block;
    height: 1.2em;
    width: 1.2em;
    border-radius: 50%;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
}

.entry-item div .summary {
    display: inline-block;
    margin-left: 2em;
}

.entry-item div .date {
    display: block;
}

.version-span {
    height: 3.6em;
}

@media screen and (max-width: 640px) {
    .entry-item {
        height: 3.6em;
    }
    .entry-item > div:first-child > p {
        max-width: 70%;
    }

    .version-span {
        height: 6em;
    }
    
    .version-span > div > div {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 641px) {
    .entry-item {
        background-color: rgba(0,0,0,0);
        transition: all 0.3s;
    }

    .entry-item:hover {
        background-color: rgba(0,0,0,0.3);
    }

    .entry-item div .summary {
        transition: all 0.3s;
    }

    .entry-item:hover div .summary {
        margin-left: 3em;
    }
}

.entry-item .line, .effort-item .line {
    position: absolute;
    height: 2.4em;
    width: 2px;
    top: 0;
    left: 0.55em;
    z-index: -1;
}

.effort-item .line {
    left: -2px;
}

.effort-item {
    height: 3.6em;
    font-size: 1.2em;
    margin-left: 0.55em;
    padding-left: 1.65em;
    border-left: 2px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-right: 1em;
}
@media screen and (max-width: 640px) {
    .effort-item {
        height: 6em;
    }
}

.effort-item p {
    margin: 0;
}

.effort-item div p:first-child {
    font-weight: bold;
}

.effort-entry {
    margin-left: 2em !important;
}





/* Downloads */

.download {
    margin-bottom: 4em;
    background-color: rgba(0,0,0,0.2);
    padding: 2em;
}

.download h3 {
    margin: 0;
}
.download > h3 a {
    text-decoration: none;
}
.download > h3 a:hover {
    text-decoration: underline;
}
.download > h3 a:last-child {
    margin-left: 0.25em;
}

.download > p {
    margin: 0;
    font-style: italic;
}

.older-downloads {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 1em;
}
.older-downloads > a {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
}
.older-downloads > a > p {
    margin: 0.5em 0;
}

.download .files h3 {
    margin-bottom: 0.5em;
}

.download .files ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.download .files li {
    border: 1px solid rgba(255,255,255,0.1);
    border-bottom: 0;
}

.download .files li:last-child {
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.download .files li > a {
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
    font-weight: 700;
}

.download .files li > a:hover {
    text-decoration: underline;
}
.entry-list-item {
    margin-bottom: 2em;
}

.entry-list-item-page-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1em 0;
    font-size: 1.2em;
}

.entry-list-item .image-gallery-container {
    margin-top: 2em;
}
.entry-week-heading {
    text-align: right;
    border-bottom: 1px solid white;
}

.entry-week-heading h2 {
    margin: 0;
}

.entry-week-heading h4 {
    margin-top: 0;
    margin-bottom: 0.75em;
}

.entry-day a:after {
    content: ",";
    margin-right: 0.3em;
}

.entry-day a:last-child:after {
    content: "" !important;
}

.more-entries-container {
    text-align: center;
}

.more-entries-button {
    border: 0;
    padding: 1em 2em;
    margin-top: 2em;
    font-size: 1.2em;
}
.entry-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    background-position: center center;
    height: 200px;
}

.entry-tile a.hover-tile {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;

    padding: 20px 40px;
    height: 200px;
    width: 100%;

    text-decoration: none;
    
    overflow-y: hidden;
}

.entry-tile a h4 {
    margin: 0 0 0 0;
    border-bottom: 1px solid white;
    text-shadow: 2px 2px rgba(0,0,0,0.8);
}

.entry-tile-date {
    margin: 0 0 1em 0;
    font-style: italic;
}

.entry-tile-content p {
    margin: 0;
    text-shadow: 2px 2px rgba(0,0,0,0.8);
}
.next-prev-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    grid-gap: 1.5em;
    gap: 1.5em;
    padding-top: 2em;
    margin: 2rem 0;
    padding-bottom: 2rem;
}

@media screen and (max-width: 640px) {
    .next-prev-container {
        grid-template-columns: 1fr;
    }
}

.entry-detail-project-header {
    width: 100%;    
    background-size: cover;
    background-position: center;
}

.entry-detail-project-header a {
    display: block;
    color: #fff;
    text-decoration: none;
    padding: 2em calc((100% - 1000px) * 0.5);
    background-color: rgba(0,0,0,0.2);
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
    transition: all 0.3s;
}

@media screen and (max-width: 640px) {
    .entry-detail-project-header a {
        padding-left: 1em;
    }
}

@media screen and (min-width: 641px) {
    .entry-detail-project-header a:hover {
        background-color: rgba(0,0,0,0.5);
        box-shadow: 3px 8px 7px rgba(0,0,0,0.5);
        padding-left: calc((100% - 1000px) * 0.5 + 50px);
    }
}

.next-prev-container .no-more {
    display: grid;
    place-items: center;
    font-size: 0.8em;
    color: rgba(255,255,255,0.6);
}

.entry-effort-indicator {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 1.5em;
}

.entry-effort-indicator p:first-child {
    font-size: 1.4em;
    font-weight: bold;
}

.entry-effort-indicator p {
    margin: 0;
}
.entryToLabel > ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.entryToLabel > ul > li {
    display: grid;
    place-items: center;
    width: 100%;
    border-radius: 5px;
    margin: 0.5em;
    background-color: rgba(0,0,0,0.1);
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    padding: 0.5em;
    cursor: pointer;
    transition: all 0.3s;
}

.entryToLabel > ul > li:hover {
    background-color: rgba(50, 50, 50, 0.1);
    box-shadow: 10px 10px 10px rgba(0,0,0,0.3);
}
.effort-tile {
    position: absolute;
    border: 1px solid white;
    border-radius: 5px;
    transition: all 0.3s;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    display: block;
    text-decoration: none;
}

.effort-tile-inner {
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    padding: 0.5em;
    overflow: hidden;
}

.effort-tile-inner > div {
    width: 100%;
}

.effort-tile p {
    margin: 0;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    font-weight: 400;
    z-index: 2;
    text-align: center;
    font-size: 0.8em;
    transition: all 0.3s;
}

.effort-tile-inner p {
    display: inline-block;
    margin: 0;
}

.effort-tile-inner p:first-child {
    width: 0%;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 0.25em;
}

.effort-tile-inner p:last-child {
    text-align: left;
    overflow: hidden;
    margin-left: 0.25em;
    width: calc(100% - 0.5em);
}

.effort-tile:hover {
    width: 100% !important;
    left: 0px !important;
    z-index: 100;
}

.effort-tile:hover p {
    font-size: 1em;
}

.effort-tile:hover .effort-tile-inner p:last-child {
    width: calc(50% - 0.5em);
}

.effort-tile:hover .effort-tile-inner p:first-child {
    width: calc(50% - 0.5em);
}

.effort-tile-entries {
    position: relative;
}

.effort-tile-entries > div {
    position: absolute;
    width: 100%;
    height: 10px;
    z-index: 1;
    overflow-x: hidden;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.effort-tile-entries > div > div {
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,0.3);
    border-radius: 50%;
}
.dashboard header {
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 640px) {
    .dashboard header {
        flex-direction: column;
    }

    .dashboard header div:first-child {
        margin-bottom: 1em;
    }
}

.dashboard header h1 {
    margin: 0;
}

.dashboard header > div:first-child {
    margin: 0;
    width: 80%;
}

.dashboard header a {
    display: inline-block;
    margin: 0.5em 1em 0 0;
    font-size: 1.2em;
}

.dashboard h2 {
    margin: 0;
}

.dashboard-project-table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 2em;
}

.dashboard-project-table th {
    height: 1.5em;
    border-bottom: 1px solid white;
    text-align: left;
    font-size: 1.4em;
    padding: 0.5em;
}

.dashboard-project-table thead span {
    margin-left: 1em;
}

.dashboard-project-table td {
    font-size: 1.2em;
    cursor: pointer;
    padding: 0.5em;
}

@media screen and (max-width: 640px) {
    .dashboard-project-table th {
        font-size: 1.2em;
    }
    .dashboard-project-table td {
        font-size: 1em;
        padding: 0.2em;
    }
}

.dashboard-project-table tr:nth-child(even) {
    background-color: rgba(0,0,0,0.2);
}

@media screen and (min-width: 641px) {
    .dashboard-project-table tbody tr, .dashboard-project-table thead th {
        background-color: rgba(0,0,0,0);
        transition: all 0.3s;
    }

    .dashboard-project-table tbody tr:hover, .dashboard-project-table thead th:hover {
        background-color: rgba(0,0,0,0.4);
    }
}

.dashboard-buttons {
    margin-bottom: 1em;
}

.dashboard-buttons a {
    margin-right: 2em;
}
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: #222;
    color: #fff;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
}

#root, .App {
    min-height: 100vh;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 64px - 2em);
}

p a {
    color: #A5F8D3;
}

a {
    color: #fff;
}

a:visited {
    color: auto;
}

.container {
    width: 1000px;
    margin: 0 auto;
    flex: 1 0 auto;
}

@media screen and (max-width: 640px) {
    .container {
        width: auto;
        margin: 0;
        padding: 0 1em;
        /* padding-right: 2em; */
    }
}

.hidden {
  display: none;
}

a.hover-tile {
    text-decoration: none;
    padding: 0.5em 1em;
}

.hover-tile {
    color: #fff;
    background-color: rgba(0,0,0,0.6);
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    text-shadow: 2px 2px rgba(0,0,0,0);
    transition: all 0.3s;
}

@media screen and (min-width: 641px) {
    .hover-tile:hover {
        background-color: rgba(0,0,0,0);
        box-shadow: 2px 7px 10px rgba(0,0,0,0.4);
        text-shadow: 2px 2px rgba(0,0,0,0.5);
    }
}

.edit-button {
    text-decoration: none;
    vertical-align: super;
    font-size: 50%;
}

@media screen and (max-width: 640px) {
    .hover-tile {
        background-color: rgba(0,0,0,0.3);
        transition: none;
    }
}

h1, h2 {
    font-family: 'Patua One', cursive;
    font-weight: 400;
}

.footer {
    background-color: #111;
    height: 64px;
    flex-shrink: 0;
    margin-top: 4em;
    padding: 0 calc((100% - 1000px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #999;
}

@media screen and (max-width: 640px) {
    .footer {
        padding: 0 1em;
    }
}
