.main-nav {
    min-height: 64px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    margin-bottom: 2em;
    z-index: 100;
    position: relative;
}

.main-nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    position: relative;
    z-index: 100;
}

@media screen and (max-width: 640px) {
    .main-nav .container {
        box-shadow: 5px 5px 5px rgba(0,0,0,0.2);     
    }
}

.main-nav .logo {
    height: 64px;

    font-size: 1.4em;
    font-weight: bold;
    color: #B294E6;
    
    text-decoration: none;
}

.main-nav .logo div {
    display: inline-block;
    height: 100%;
}

.main-nav .logo a {
    padding: 0 1em;
    margin-left: -1em;
}

@media screen and (max-width: 640px) {
    .main-nav .logo a {
        padding-right: 0;
    }
}

.main-nav ul {
    padding: 0;
    margin: 0 2em 0 0;
    height: 64px;
    display: inline-block;
    
    list-style: none;
}

.main-nav ul:last-child {
    margin: 0;
}

.main-nav li {
    display: inline-block;
    height: 100%;
}

.main-nav a {
    display: flex;
    align-items: center;
    padding: 0 0.5em;
    height: 100%;

    text-decoration: none;
    font-size: 1.1em;

    color: #B294E6;
    border-bottom: 3px solid rgba(207, 236, 231, 0);
    background-color: rgba(207, 236, 231, 0);
}

@media screen and (max-width: 640px) {
    .main-nav a {
        padding: 0 0.5em;
    }
}

@media screen and (min-width: 641px) {
    .main-nav a {
        transition: all 0.3s;
    }
    .main-nav a:hover {
        border-bottom: 3px solid rgba(207, 236, 231, 1);
        background-color: rgba(207, 236, 231, 0.04);
    }
}

.hamburger-button {
    border: 0;
    color: white;
    font-size: 1.4em;
    width: 45px;
    height: 45px;
    display: grid;
    place-items: center;
    display: inline-block;
}

.hamburger-interior .search-form {
    padding: 0.5em;
    width: 100%;
}

.hamburger-interior .search-input {
    width: 100%;
}